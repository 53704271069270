<script setup lang="ts">
const props = withDefaults(
  defineProps<{ alertType?: string, persistent?: boolean }>(),
  { alertType: 'info', persistent: false },
);

const alertOpen = ref(true);

const colors = computed(() => {
  // need to explicitly add the classes here thanks to PurgeCSS; safelist patterns don't seem to work
  switch (props.alertType) {
    case 'success':
      return {
        bg: 'bg-green-50',
        text: 'text-green-800',
        close: 'text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100',
      };
    case 'error':
      return {
        bg: 'bg-red-50',
        text: 'text-red-800',
        close: 'text-red-500 hover:bg-red-100 focus:outline-none focus:bg-red-100',
      };
    case 'warning':
      return {
        bg: 'bg-yellow-50',
        text: 'text-yellow-800',
        close: 'text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:bg-yellow-100',
      };
    default:
      return {
        bg: 'bg-blue-50',
        text: 'text-blue-800',
        close: 'text-blue-500 hover:bg-blue-100 focus:outline-none focus:bg-blue-100',
      };
  }
});

function toggleAlert() {
  alertOpen.value = !alertOpen.value;
}
</script>

<template>
  <div
    v-if="alertOpen"
    class="rounded-md p-4"
    :class="colors.bg"
  >
    <div class="flex">
      <div class="shrink-0">
        <!-- Heroicon name: check-circle -->
        <svg
          v-if="alertType === 'success'"
          class="size-5 text-green-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>

        <svg
          v-if="alertType === 'error'"
          class="size-5 text-red-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >

          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>

        <svg
          v-if="alertType === 'warning'"
          class="size-5 text-yellow-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >

          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>

        <!-- Heroicon name: information-circle -->
        <svg
          v-if="alertType === 'info'"
          class="size-5 text-blue-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          />
        </svg>
      </div>

      <div class="ml-3">
        <div
          class="text-sm font-medium leading-5"
          :class="colors.text"
        >
          <slot />
        </div>
      </div>

      <div class="ml-auto pl-3">
        <div class="-m-1.5">
          <button
            v-if="!persistent"
            class="inline-flex rounded-md p-1.5 transition duration-150 ease-in-out"
            :class="colors.close"
            aria-label="Dismiss"
            @click="toggleAlert"
          >
            <!-- Heroicon name: x -->
            <svg
              class="size-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
